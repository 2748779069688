import React from "react";
import { graphql, Link } from "gatsby";
import day from "dayjs";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Circles from "../components/svg/Circles";
import InsightNav from "../components/insight/insight-nav";
import Sidebar from "../components/sidebar";

export default function Insight({ data }) {
  return (
    <Layout>
      <Seo seo={data.insight.seo} />

      <header className="insight__header">
        <div className="insight__circle-svg__wrapper">
          <Circles className="insight__circle-svg" variant="orange" />
          <Link
            to={data.insight.categories?.nodes?.[0]?.uri}
            className="insight__category"
          >
            {data.insight.categories?.nodes?.[0]?.name}
          </Link>
        </div>
        <div className="insight__header__text-wrapper">
          <p className="insight__header__tags">
            {data.insight.tags?.nodes?.map((tag, index) => (
              <Link
                key={index}
                to={tag.uri}
                className="insight__header__tag pointer"
              >
                {tag.name}
              </Link>
            ))}
          </p>
          <p className="insight__header__date">
            {day(data.insight?.date).format("D MMMM YYYY")}
          </p>
          <p className="insight__header__title">{data.insight?.title}</p>
        </div>
      </header>
      <main className="insight__main">
        <aside className="insight__aside">
          {data.insight.insight?.authorDetails?.map((author, index) => (
            <div key={index} className="insight__author">
              <div className="insight__author__role">{author.role}:</div>
              <div>{author.fullName}</div>
              <div>{author.email}</div>
            </div>
          ))}

          <Sidebar />
        </aside>
        <article className="insight__article">
          {data.insight.insight?.inBrief && (
            <div className="insight__in-brief">
              <p className="insight__in-brief__heading">In brief</p>

              <div
                className="wysiwyg__content"
                dangerouslySetInnerHTML={{
                  __html: data.insight.insight.inBrief,
                }}
              />
            </div>
          )}

          <div
            className="wysiwyg__content insight__content"
            dangerouslySetInnerHTML={{ __html: data.insight.content }}
          />

          {data.insight.insight?.downloads?.length > 0 && (
            <div className="mt-4 mb-6">
              <p className="pb-1">
                <strong className="page__text">Downloads</strong>
              </p>

              {data.insight?.insight?.downloads?.map(({ file }, index) => (
                <a
                  className="text-blue inline-block page__text"
                  key={index}
                  rel="noreferrer"
                  target="_blank"
                  href={file?.mediaItemUrl}
                >
                  <u>{file?.title}</u>
                </a>
              ))}
            </div>
          )}
          <InsightNav
            nextInsight={data.nextInsight}
            previousInsight={data.previousInsight}
          />
        </article>
      </main>
      <div className="mb-10" />
    </Layout>
  );
}

export const query = graphql`
  query ($databaseId: Int!, $nextId: Int, $previousId: Int) {
    insight: wpInsight(databaseId: { eq: $databaseId }) {
      title
      content
      date
      tags {
        nodes {
          name
          slug
          uri
        }
      }
      insight {
        authorDetails {
          email
          fullName
          phoneNumber
          role
        }
        inBrief
        downloads {
          file {
            title
            link
            mediaItemUrl
          }
        }
      }
      categories {
        nodes {
          name
          uri
        }
      }
      seo {
        fullHead
      }
    }
    nextInsight: wpInsight(databaseId: { eq: $nextId }) {
      title
      uri
    }
    previousInsight: wpInsight(databaseId: { eq: $previousId }) {
      title
      uri
    }
  }
`;
