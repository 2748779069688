import React from "react";
import { Link } from "gatsby";

export default function InsightNav({ nextInsight, previousInsight }) {
  return (
    <div className="insight__navigation">
      {previousInsight && (
        <Link to={previousInsight.uri} className="insight__navigation__link">
          <span className="insight__navigation__pre">Previous: </span>
          <p>{previousInsight.title}</p>
        </Link>
      )}
      {nextInsight && (
        <Link to={nextInsight.uri} className="insight__navigation__link">
          <span className="insight__navigation__pre">Next: </span>
          <p>{nextInsight.title}</p>
        </Link>
      )}
    </div>
  );
}
